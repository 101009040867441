/*
 * @Author: your name
 * @Date: 2021-10-21 16:48:54
 * @LastEditTime: 2021-10-21 16:52:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\api\course.js
 */
import request from "@/utils/request.js";
import qs from "qs";

export function getCourseType() {
  return request({
    url: `/course/category/list`,
    method: "get",
  });
}

// 一键删除所有错题
export function deleteAllError() {
  return request({
    url: `/topicError/deleteAll`,
    method: "post",
  });
}

// 删除某个错题
export function deleteError(id) {
  return request({
    url: `/topicError/deleteError`,
    method: "post",
    params: {
      topicId: id,
    },
  });
}

export function getCourseDetail(data) {
  return request({
    url: `/course/info`,
    method: "get",
    params: data,
  });
}

export function getClassByContent(data) {
  return request({
    url: `/class/listPage`,
    method: "get",
    params: data,
  });
}

export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data: qs.stringify(data),
  });
}

export function wxLogin(data) {
  return request({
    url: "/user/public/login",
    method: "post",
    data: qs.stringify(data),
  });
}

// 获取视频地址
export function getVideoPlayAuth(id) {
  return request({
    url: "/course/content/getVideoPlayAuth?id=" + id,
    method: "get",
  });
}

export function getAgent(id) {
  return request({
    url: "/agent/info?id=" + id,
    method: "get",
  });
}

// 获得随机练习的题目
export function getRondomInfo(classId) {
  return request({
    url: "/test/rondom/info?classId=" + classId,
    method: "get",
  });
}

// 获得班级的练习
export function getTestInfo(id) {
  return request({
    url: "/test/info?id=" + id,
    method: "get",
  });
}

// 获得练习的题目
export function getListByCourse(id) {
  return request({
    url: "/test/listByCourse?courseId=" + id,
    method: "get",
  });
}

// 提交练习
export function submitTest(data) {
  return request({
    url: "/test/submit",
    method: "post",
    data: qs.stringify(data),
  });
}

// 获得练习记录详情
export function getRecordInfo(data) {
  return request({
    url: "/test/record/info",
    method: "get",
    params: data,
  });
}

// 获取用户信息
export function getCurrentUser() {
  return request({
    url: "/user/currentUser",
    method: "get",
  });
}

// 获取选择班级后的进度条信息
export function getProgress(classId) {
  return request({
    url: "/progress/get?classId=" + classId,
    method: "get",
  });
}

//上传文件
// export function uploadFile(data) {
//   return request({
//     url: '/system/upload/file',
//     method: 'post',
//     data:qs.stringify(data)
//   })
// }

//修改个人用户
export function updateUserInfo(data) {
  return request({
    url: "/user/update/info",
    method: "post",
    data: qs.stringify(data),
  });
}

//获取练习记录
export function getRecord(data) {
  return request({
    url: "/test/record/listPage",
    method: "get",
    params: data,
  });
}

//提交随机练习试卷
export function submitRondomTest(data) {
  return request({
    url: "/test/rondom/submit",
    method: "post",
    params: data,
  });
}

// 获得练习记录信息
export function getPracticeRecordInfo(data) {
  return request({
    url: "/test/record/info",
    method: "get",
    params: data,
  });
}

// 获得班级课件列表
export function getCoursewareList(data) {
  return request({
    url: "/courseware/listByClass",
    method: "get",
    params: data,
  });
}

// 获得课程内容详情
export function getCourseContentDetail(data) {
  return request({
    url: "/course/content/info",
    method: "get",
    params: data,
  });
}

// 根据班级id获取课时表
export function getTimetable(data) {
  return request({
    url: "/timetable/listByClass",
    method: "get",
    params: data,
  });
}

// 根据班级id获取课程内容
export function getCourseInfo(classId) {
  return request({
    url: "/course/content/listByClass?classId=" + classId,
    method: "get",
  });
}

// 根据课程id获取班级列表
export function getCourseClass(data) {
  return request({
    url: "/class/listPage",
    method: "get",
    params: data,
  });
}

// 添加答疑
export function addAnsweringQuestion(data) {
  return request({
    url: "/question/add",
    method: "post",
    params: data,
  });
}

//修改密码
export function updatePassword(data) {
  return request({
    url: "/user/update/password",
    method: "post",
    data: qs.stringify(data),
  });
}

export function getNotice(data) {
  return request({
    url: "/timetable/listByClass",
    method: "get",
    params: data,
  });
}

export function gettimetable(data) {
  return request({
    url: "/timetable/info",
    method: "get",
    params: data,
  });
}
//上课通知
export function getNoticeInfo(data) {
  return request({
    url: "/timetable/listByClass",
    method: "get",
    params: data,
  });
}

// 轮播图
export function getSwiper(params) {
  return request({
    url: "/banner/list",
    method: "get",
    params,
  });
}

//获取课程
export function getCourse(data) {
  return request({
    url: "/course/listPage",
    method: "get",
    params: data,
  });
}

//获取全部问题
export function getQuestionList(data) {
  return request({
    url: "/question/list",
    method: "get",
    params: data,
  });
}
//获取我的问题
export function getUserQuestionList(data) {
  return request({
    url: "/question/listPageByUser",
    method: "get",
    params: data,
  });
}
//获取问题详情
export function getQuestionInfo(data) {
  return request({
    url: "/question/info",
    method: "get",
    params: data,
  });
}
//查看回复列表

export function getQuestionReplyList(data) {
  return request({
    url: "/question/reply/list",
    method: "get",
    params: data,
  });
}

export function addQuestionReply(data) {
  return request({
    url: "/question/reply/add",
    method: "post",
    data: qs.stringify(data),
  });
}

export function getCategoryList() {
  return request({
    url: "/course/category/list",
    method: "get",
  });
}

//学习记录
export function getRecentStudy(params) {
  return request({
    url: "/course/content/record/latelyStudy",
    method: "get",
    params,
  });
}

export function getClassNotice() {
  return request({
    url: "/notice/classNotice",
    method: "get",
  });
}

// 添加课程内容观看记录
export function addCourseRecords(data) {
  return request({
    url: "/course/content/record/add",
    method: "post",
    params: data,
  });
}

// app/system/down/{path}
export function download(path) {
  return request({
    url: `/system/down/`,
    method: "get",
    // params:{
    //   path:path
    // }
  });
}

// 获得直播列表
export function getLiveList(data) {
  return request({
    url: `/live/listPage`,
    method: "get",
    params: data,
  });
}

// 获得直播详情
export function getLiveInfo(id) {
  return request({
    url: `/live/info?id=` + id,
    method: "get",
  });
}

// 获得直播地址
export function getLivePlay(data) {
  return request({
    url: `/live/play`,
    method: "post",
    data: qs.stringify(data),
  });
}

//微信分享
export function share(url) {
  return request({
    url: "/system/share",
    method: "get",
    params: {
      url: url,
    },
    // data:qs.stringify(data)
  });
}
// 添加错题收藏
export function addTopicCollect(data) {
  return request({
    url: "/topicCollect/add",
    method: "post",
    data: qs.stringify(data),
  });
}

// 添加错题收藏
export function topicCollectDelete(data) {
  return request({
    url: "/topicCollect/delete",
    method: "post",
    data: qs.stringify(data),
  });
}

// 题目解析
export function testAnalysis(data) {
  return request({
    url: "/test/analysis",
    method: "post",
    data: qs.stringify(data),
  });
}

//错题收藏的详细信息
export function addTopicCollectInfo() {
  return request({
    url: "/topicCollect/info",
    method: "post",
  });
}

// 获取收藏分页
export function topicCollectListPage(data) {
  return request({
    url: "/topicCollect/listPage",
    method: "get",
    params: data,
  });
}

// 获取错题收藏详情
export function topicCollectInfo(data) {
  return request({
    url: "/topicCollect/info",
    method: "get",
    params: data,
  });
}

// 获取热门知识点
export function hotKnowledgePoints(data) {
  return request({
    url: "/course/hot/listPage",
    method: "get",
    params: data,
  });
}

// 获取热门知识点
export function getHotInfo(data) {
  return request({
    url: "/course/hot/info",
    method: "get",
    params: data,
  });
}

// 添加兴趣课程
export function addInterestCourse(data) {
  return request({
    url: "/user/interest/add",
    method: "post",
    data: qs.stringify(data),
  });
}

// 根据课程id查询课程
export function findCourseById(data) {
  return request({
    url: "/course/info",
    method: "get",
    params: data,
  });
}

// 添加直播记录
export function addLiveRecord(data) {
  return request({
    url: "/live/record/add",
    method: "post",
    data: qs.stringify(data),
  });
}

// 获取热门知识点视频播放凭证
export function getHotVideoPlayAuth(id) {
  return request({
    url: "/course/hot/getVideoPlayAuth?id=" + id,
    method: "get",
  });
}

//上课通知
export function getTimetableClassNotice() {
  return request({
    url: "/timetable/classNotice",
    method: "get",
  });
}

//已读

export function getTimetableRead() {
  return request({
    url: "/timetable/read",
    method: "post",
  });
}

// 错题本分页查询
export function getTopicErrorList(data) {
  return request({
    url: "/topicError/listPage",
    method: "get",
    params: data,
  });
}

// 错题本分页查询
export function getTopicErrorInfo(id) {
  return request({
    url: "/topicError/info?id=" + id,
    method: "get",
  });
}

// 根据课程获取班级
export function getClass(courseId) {
  return request({
    url: "/class/getByCourse?courseId=" + courseId,
    method: "get",
  });
}
// 保存练习记录
export function addTestRecord(data) {
  return request({
    url: "/test/record/add",
    method: "post",
    data: qs.stringify(data),
  });
}
// 查询是否存在未完成记录(返回结果0 不存在 1 存在)
export function existTestRecord(testId) {
  return request({
    url: "/test/record/exist?testId=" + testId,
    method: "get",
  });
}
// 获取上次练习记录
export function getTestRecord(testId) {
  return request({
    url: "/test/record/get?testId=" + testId,
    method: "get",
  });
}
