/*
 * @Author: your name
 * @Date: 2021-10-19 16:59:31
 * @LastEditTime: 2021-10-19 16:59:44
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\utils\request.js
 */

import axios from 'axios'
import baseUrl from './api_host'
import router from '@/router/index.js'
import {
  Message
} from 'element-ui'
const service = axios.create({
  baseURL: baseUrl.baseURL,
  timeout: 15000,
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
})


service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('web-token')
    if (token) {
      config.headers['authentication'] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 200) {
      // Message.error(res.msg)
      return res
    } else if (res.code == 402) {
      if (localStorage.getItem('web-token')) {
        Message.error('您的账号被其他人登录，请重新登录')
        localStorage.removeItem("web-token")
        setTimeout(function(){
          router.push('/login')
        },1500)
      }
      return res
    } else if (res.code !== 500) {
      // Toast.fail(res.msg)
      Message.error(res.msg);
    } else if (res.msg !== "系统异常") {
      // Toast.fail(res.msg)
      Message.error(res.msg);
    }
  },
  error => {
    var errorStr = String(error)
    if (errorStr.includes('Network Error')) {
      Message.error('无可用网络')
      // Toast.fail({
      //   message: '无可用网络',
      //   duration: 2000
      // });
    } else if (errorStr.includes('timeout')) {
      Message.error('请求超时')
      // Toast.fail({
      //   message: '请求超时',
      //   duration: 2000
      // });
    }
    return Promise.reject(error)
  }
)
export default service